<template>
  <div class="product-wrap about-wrap">
    <Header is-active="about" theme="white"></Header>
    <div class="empty-nav" id="brief"></div>
    <div class="sub-banner banner-about">
      <img src="../assets/img/about/banner.jpg" alt="云昇新控-官网-关于云昇新控-关于我们">
    </div>
    <div class="white-wrap bg-box">
      <div class="max-center pd-5">
        <h2 class="fz-sm align-ct mt-5">重庆云昇新控智能科技有限公司是一家自动化系统集成、工业物联网研发、销售和工业整体解决方案服务公司。</h2>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="auto-img">
              <img src="../assets/img/about/pic_1.jpg" alt="云昇新控-官网-关于云昇新控-关于我们">
            </div>
          </el-col>
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="fz-sm align-lt mt-5"><h5>云昇新控简介</h5></div>
            <div class="txt-box">
              <p>重庆云昇新控智能科技有限公司成立于2020年，总部位于重庆，在江津珞璜建有独立工厂，并在成都、西安等地设有办事处。</p>
              <p>
                早在公司成立之初，主创人员就已深入了解整个市场，从事相关行业工作近二十年，掌握了丰富的市场信息，积累了大量的行业经验，所以，公司虽是行业新秀，也已拿下多个品牌项目，保质保量推进项目开展，赢得业主赞誉。现已成为西南地区该产业链中具有影响力的品牌之一，服务辐射重庆、四川、西安等区域，涉及100多个区县，遥遥领先西南地区其他产业。 </p>
              <p>公司主要从事软件开发、电气自动化系统集成及技术开发、计算机软硬件研发。为此广招贤士，打造了一支技术精湛、配合默契的精良团队，依托雄厚资源，凭借团队的努力，争取每一步都走得更快更好更高效。</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="culture"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="customized-box advantage-box">
          <div class="td-item" style="padding: 0 0 3%">
            <div class="max-img">
              <img src="../assets/img/about/pic_2.jpg" alt="云昇新控官网-企业文化">
            </div>
            <h6 class="color-blue">企业文化</h6>
            <p>坚持创新驱动 追求品质卓越</p>
            <p>创新是企业的灵魂 卓越是企业的生命</p>
          </div>
          <div class="td-item" style="padding: 0 0 3%">
            <div class="max-img">
              <img src="../assets/img/about/pic_3.jpg" alt="云昇新控官网-企业愿景">
            </div>
            <h6 class="color-blue">企业愿景</h6>
            <p>通过连接物理世界和数字世界改造产业</p>
            <p>推动传统企业向智能企业的演进</p>
          </div>
          <div class="td-item" style="padding: 0 0 3%">
            <div class="max-img">
              <img src="../assets/img/about/pic_4.jpg" alt="云昇新控官网-企业理念">
            </div>
            <h6 class="color-blue">企业理念</h6>
            <p>精益求精 创新协作共赢</p>
          </div>
        </div>
      </div>
      <div id="join"></div>
    </div>
    <div class="max-img">
      <img src="../assets/img/about/pic_5.jpg" alt="云昇新控官网-加入我们">
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="brief-box">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="bf-item">
                <h6>自动化工程师</h6>
                <p class="sub-title">岗位职责</p>
                <div class="txt-info">
                  <p>1.负责项目售前技术交流及方案设计和编写；负责项目电气、自控产品选型；参与项目实施管理。</p>
                  <p>2.负责自动化设备或系统改造的二次图设计。</p>
                  <p>3.自动化控制系统设计、软件组态编程及调试验收等。</p>
                  <p>4.对工程项目所需电气自控系统设备进行选型和技术论证，审核设备供应商技术文件和图纸。</p>
                  <p>5.配合业主进行自控设备招标技术文件编制，参与技术谈判。</p>
                  <p>6.到工程建设现场进行设计文件交底和服务工作，协助并指导施工安装。</p>
                  <p>7.配合项目技术交流的开展及相关工作。</p>
                </div>
                <p class="sub-title">任职要求</p>
                <div class="txt-info">
                  <p>1.有系统集成项目经验；</p>
                  <p>2.熟练使用CAD软件；</p>
                  <p>3.工控行业工作4年以上；</p>
                  <p>4.精通工业自动化控制，熟练应用SIEMENS、AB、施耐德、三菱等PLC程序设计、编程；精通主流DCS/SCADA系统及触摸屏组态编程。</p>
                  <p>5.熟悉并具有设备自动化改造经验等；</p>
                  <p>6.工作认真细致、耐心、责任心强，良好的沟通能力；</p>
                  <p>7.有良好的团队配合能力、擅于与人沟通，具有良好的口头表达能力；</p>
                  <p>8.有污水处理厂、工厂等项目经验优先；</p>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="bf-item">
                <h6>销售经理</h6>
                <p class="sub-title">岗位职责</p>
                <div class="txt-info">
                  <p>1、维持和开拓新的客户资源，为客户提供优质的服务建立良好的关系</p>
                  <p>2、认真贯彻执行公司的销售管理规定和实施细则；</p>
                  <p>3、积极完成公司制定的业务指标；</p>
                  <p>4、收集市场信息，定期向上级反馈，并建议市场策略，了解客户需求，制定和执行客户销售计划，进行商务谈判，对潜在客户进行定期跟踪，定期提交销售进度报告。</p>
                </div>
                <br>
                <br>
                <br>
                <br>
                <p class="sub-title">任职要求</p>
                <div class="txt-info">
                  <p>1、大学专科及以上学历，计算机、市场营销或相关专业；</p>
                  <p>2、具有3年以上销售经验， 为人真诚，抗压性强，具有事业心和责任心；</p>
                  <p>3、善于沟通，富有责任心，工作积极主动，执行力强，较强的团队协作和独立开展工作能力；</p>
                  <p>4、具有工程建筑类、工厂业务、仪器仪表、水处理行业者优先。</p>
                </div>
                <br>
                <br>
              </div>
            </el-col>
          </el-row>
        </div>
        <p class="align-ct" style="font-size: 18px;"><i style="font-size: 28px;position: relative;top: 5px"
                                                        class="iconfont icon-youxiang"></i>简历投递地址：<span
          class="color-blue"><a class="color-blue" href="mailto:liujuhong@yunsxk.com" target="_blank">liujuhong@yunsxk.com</a>
        </span></p>

      </div>
    </div>
    <div class="max-img">
      <img src="../assets/img/about/pic_6.jpg" alt="云昇新控官网-联系我们">
      <div id="contact"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <el-row :gutter="10">
          <el-col :span="18">
            <div class="txt-box">
              <p><i class="iconfont color-blue icon-dizhi"></i><a style="color: #595959;" target="_blank"
                                                                  href="http://api.map.baidu.com/marker?location=29.6473,106.555455&title=我的位置&content=重庆云昇新控智能科技有限公司&output=html&src=webapp.baidu.openAPIdemo">地址：重庆市渝北区金开大道1003号22层2号</a>
              </p>
              <p><i class="iconfont color-blue icon-phone"></i><a style="color: #595959;" href="tel: 023-6780 9905">电话：023-6780
                9905</a></p>
              <p><i class="iconfont color-blue icon-youxiang"></i><a style="color: #595959;"
                                                                     href="mailto:marketing@yunsxk.com"
                                                                     target="_blank">邮箱：marketing@yunsxk.com</a></p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="auto-img">
              <img src="../assets/img/about/pic_7.jpg" alt="扫码关注微信公众号">
              <p>扫码关注微信公众号</p>
            </div>
          </el-col>
        </el-row>
        <div class="auto-img mt-5">
          <a target="_blank"
             href="http://api.map.baidu.com/marker?location=29.6473,106.555455&title=我的位置&content=重庆云昇新控智能科技有限公司&output=html&src=webapp.baidu.openAPIdemo">
            <img src="../assets/img/about/address.png" alt="云昇新控-地址">
          </a>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import BackTop from '../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '关于我们,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控关于我们-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../public/css/product.less";

.about-wrap {
  .banner-about {
    //background: url("../assets/img/about/banner.jpg") no-repeat center;
    //background-size: cover;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .bg-box {
    background: url("../assets/img/about/bg.png") repeat center;
  }

  .brief-box {
    width: 100%;
    height: auto;

    .bf-item {
      padding: 4%;
      margin-bottom: 4%;
      text-align: left;
      background: #FFFFFF;
      box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.16);
      font-family: Microsoft YaHei;

      h6 {
        font-size: 24px;
        font-weight: 400;
        color: #1890FF;
        padding-bottom: 2%;
      }

      .sub-title {
        padding: 4% 0;
        font-size: 18px;
        font-weight: 400;
        color: #595959;
      }

      .txt-info {
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #8C8C8C;
      }
    }
  }

}

</style>
